	<template>
  <div class="container">
    <!--预览-->
    <div class="goback1">
      <div class="goBtn">
        <van-nav-bar
          :title="fileName"
          left-arrow
          @click-left="$router.back()"
        />
        <van-progress pivot-text="" :percentage="percent" stroke-width="2" />
      </div>
    </div>
    <div>
      <!-- <pdf
        :src="pdfSrc"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        @loaded="loadPdfHandler"
        @progress="loading"
      >
      </pdf> -->

      <pdf
        :src="pdfSrc"
        @progress="loading"
        @page-loaded="pageLoad"
        v-for="i in numPages"
        :key="i"
        :page="i"
      ></pdf>
    </div>
    <van-loading
      size="40px"
      class="loadingIcon"
      v-if="isLoading"
      color="#1989fa"
    />
    <!--页码-->
    <!-- <div class="pdf pageGroup">
      <div class="pagesize">
        <span
          @click="changePdfPage(0)"
          class="turn"
          :class="{ grey: currentPage == 1 }"
          >上一页</span
        >
        {{ currentPage }} / {{ pageCount }}
        <span
          @click="changePdfPage(1)"
          class="turn"
          :class="{ grey: currentPage == pageCount }"
          >下一页</span
        >
      </div>
    </div> -->
  </div>
</template>
	<script>
import pdf from "vue-pdf"; // 引入pdf组件
export default {
  metaInfo: {
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2,user-scalable=yes",
      },
    ],
  },
  components: { pdf }, // 引入pdf组件
  data() {
    return {
      // currentPage: 0, // pdf文件页码
      // pageCount: 0, // pdf文件总页数
      fileType: "pdf", // 文件类型
      pdfSrc: "", // pdf文
      percent: 0,
      pdfUrl: "",
      numPages: 1,
      isLoading: true,
      fileName: "",
    };
  },
  watch: {},
  methods: {
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    // changePdfPage(val) {
    //   // console.log(val)
    //   if (val === 0 && this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    //   if (val === 1 && this.currentPage < this.pageCount) {
    //     this.currentPage++;
    //   }
    // },
    // pdf加载时
    // loadPdfHandler(e) {
    //   this.currentPage = 1; // 加载的时候先加载第一页
    // },
    loading(data) {
      this.percent = (data / 1) * 100;
      console.log(data, "加载");
    },
    pageLoad(num) {
      console.log(num, "dasw");
      console.log(this.numPages, "总页数");
      if (this.numPages == num) {
        this.isLoading = false;
      }
    },
    getNumPages() {
      try {
        console.log(this.pdfSrc, "文件地址");
        var loadingTask = pdf.createLoadingTask(this.pdfSrc);
        loadingTask.promise
          .then((pdf) => {
            this.pdfUrl = loadingTask;
            this.numPages = pdf.numPages;
          })
          .catch((err) => {
            console.error("pdf加载失败");
          });
      } catch (error) {
        console.log(error, "错误");
      }
    },
  },
  created() {
    console.log(this.$route.query.fileUrl, "url");
    console.log(this.$route.query.fileName, "urldetail");
    // pdf 地址
    this.pdfSrc = this.$route.query.fileUrl;
    // 文件名
    this.fileName = this.$route.query.fileName;
  },
  mounted() {
    this.getNumPages();
  },
  beforeDestroy() {
    this.isLoading = true;
  },
};
</script>
	<style lang="less" scoped>
.pdf-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0.28rem;
  span {
    width: 100%;
  }
}
.pageGroup {
  padding-left: 50px;
}
.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

